<template>
    <div class="wrap">
        <CancelUpdate v-if="cancelModalStatus" @closeCancel="cancelModalStatus = false" />
        <div class="space_pc">
            <section class="main registration">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom:0px;">큐레이션 설정</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">주로 어떤 용도로 차량을 이용하시나요?</h3>
                        </div>
                        <div class="pagnation">
                            <span></span>
                            <span style="width:14.28%"></span>
                        </div>
                        <div class="three_type fade-up" id="car_purpose" style="margin-top:0px;">
                            <div>
                                <ul class="acodian_tab" style="padding-left:0px;" @click="clickPurpose()">
                                    <li style="
                      background: url('/media/img/unsplash_CT_8qZeXx78.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carPurpose" value="1" hidden />
                                        <p style="margin-bottom:0px;">출퇴근용</p>
                                        <div :class="{ on: selectItem[0] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_wNL9y7DtgNo.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carPurpose" value="2" hidden />
                                        <p style="margin-bottom:0px;">일상생활용</p>
                                        <div :class="{ on: selectItem[1] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_8e2gal_GIE8.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carPurpose" value="3" hidden />
                                        <p style="margin-bottom:0px;">업무용</p>
                                        <div :class="{ on: selectItem[2] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_JYl_VbBdobw.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carPurpose" value="4" hidden />
                                        <p style="margin-bottom:0px;">드라이브용</p>
                                        <div :class="{ on: selectItem[3] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="form_bottom space_1">
                            <div class="form_bottom_box">
                                <div>
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         style="display: none;">
                                        <path d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                              fill="white" />
                                    </svg>
                                    <div>
                                        <!-- <p style="margin-bottom:0px;text-decoration:none;">럭셔리</p> -->
                                    </div>
                                </div>
                                <div>
                                    <button id="prev_btn" style="text-align:center;" @click="clickPre()">이전</button>
                                    <button id="next_btn" style="text-align:center;" v-if="carPurpose.purposeNo.length > 0"
                                            @click="updateCuration()">다음</button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a href="#" @click="clickPre()">
                        <img src="/media/images/icon_back_24.svg">
                    </a>
                    <h2 style="margin-bottom:0px;">큐레이션 설정</h2>
                    <div @click="linkMySpace()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.00023 5.58672L11.9502 0.636719L13.3642 2.05072L8.41423 7.00072L13.3642 11.9507L11.9502 13.3647L7.00023 8.41472L2.05023 13.3647L0.63623 11.9507L5.58623 7.00072L0.63623 2.05072L2.05023 0.636719L7.00023 5.58672Z"
                                fill="white" />
                        </svg>
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width:14.28%"></span>
                </div>
            </div>
            <section class="main registration type_choice">
                <div class="main_box">
                    <h1 style="margin-bottom:0px;">큐레이션 설정</h1>
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">주로 어떤 용도로 차량을 이용하시나요?</h3>
                        </div>
                        <div class="types" @click="clickPurposeM()">
                            <ul style="padding-left:0px;">
                                <li style="
                    background: url('/media/img/unsplash_CT_8qZeXx78.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[0] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carPurpose" value="1" hidden />
                                    <p style="margin-bottom:0px;">출퇴근용</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_wNL9y7DtgNo.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[1] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carPurpose" value="2" hidden />
                                    <p style="margin-bottom:0px;">일상생활용</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_8e2gal_GIE8.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[2] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carPurpose" value="3" hidden />
                                    <p style="margin-bottom:0px;">업무용</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_JYl_VbBdobw.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[3] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carPurpose" value="4" hidden />
                                    <p style="margin-bottom:0px;">드라이브용</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button style="text-align:center;" v-if="carPurpose.purposeNo.length > 0"
                                @click="updateCuration()">다음</button>
                        <button class="opacity3" style="text-align:center;" v-else>다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import CancelUpdate from "@/components/modal/Space/CancelUpdate.vue";
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";
import router from "@/router";

export default defineComponent({
  data () {
    return {
      carPurpose: {
        purposeNo: []
      },
      tempList: [],
      allList: ["출퇴근용", "일상생활", "업무용", "드라이브용"],
      selectItem: [],
      filterList: [],
      cancelModalStatus: false
    };
  },
  components: {
    CancelUpdate
  },
  methods: {
    linkMySpace () {
      this.cancelModalStatus = true;
    },
    updateCuration () {
      var spaceId = localStorage.getItem("spaceId");
      var carPurpose = (this.carPurpose.purposeNo).toString();
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/updateCurationTemp", { spaceId, carPurpose }, { headers })
        .then(res => {
          // console.log(res.data.result);
          router.push({ name: "MySpaceUpdateCarMood" });
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickPre () {
      router.push({ name: "UpdateCurationCar" });
    },
    clickPurpose () {
      $(document).off("click").on("click", ".acodian_tab > li", (e) => {
        // $(".acodian_tab > li").children('div').removeClass('on');
        $(e.currentTarget).children('div').toggleClass('on');
        // carPurpose 값 넣고 빼기
        var carPurposetList = this.carPurpose.purposeNo;
        var carPurpose = parseInt(e.currentTarget.querySelector('input').value);

        var found = carPurposetList.find(el => el === carPurpose);
        if (found != null) { // 선택한 값이 있을때
          var filtered = carPurposetList.filter((el) => el !== carPurpose);
          this.carPurpose.purposeNo = filtered;
        } else { // 선택한 값이 없을때
          this.carPurpose.purposeNo.push(carPurpose);
        }
      });
    },
    set () {
      $(".types ul li").children('span').hide();
    },
    clickPurposeM () {
      $(document).off("click").on("click", ".types ul li", (e) => {
        // $(".acodian_tab > li").children('div').removeClass('on');
        $(e.currentTarget).children('span').toggle();
        // carPurpose 값 넣고 빼기
        var carPurposetList = this.carPurpose.purposeNo;
        var carPurpose = parseInt(e.currentTarget.querySelector('input').value);

        var found = carPurposetList.find(el => el === carPurpose);
        if (found != null) { // 선택한 값이 있을때
          var filtered = carPurposetList.filter((el) => el !== carPurpose);
          this.carPurpose.purposeNo = filtered;
        } else { // 선택한 값이 없을때
          this.carPurpose.purposeNo.push(carPurpose);
        }
      });
    },
    getCurationTemp () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/profile/getCurationTemp', { spaceId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.tempList = res.data.result.carPurpose;

          this.selectPur();
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectPur () {
      var allList = this.allList;

      // class 부여(true / false)
      for (var i in allList) {
        this.selectItem[i] = this.tempList.indexOf(allList[i]) > -1;

        // 선택된 값 넣어주기
        var filter = allList.indexOf(this.tempList[i]) + 1;
        if (filter > 0) {
          this.filterList.push(filter);
        }
      }
      this.carPurpose.purposeNo = this.filterList;
    }
  },
  mounted () {
    this.set();
    this.getCurationTemp();
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
