<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                마지막 정보까지 수정해야 저장되며,<br>
                중단될 경우 수정한 정보는 변경되지 않습니다.<br>
                그래도 닫으시겠습니까?
            </h3>
            <div class="single-file-btn">
                <p @click="close">아니오</p>
                <p id="color-text" @click="cancel">네</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'cancelUpdate',
  methods: {
    close () {
      this.$emit('closeCancel');
    },
    cancel () {
      this.$emit('closeCancel');
      this.$router.push('/myspace');
    }
  }
};
</script>
